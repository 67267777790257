/* eslint-disable react/jsx-no-target-blank */
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import LazyLoad from 'react-lazyload'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Configuration from '../configuration'
import * as Constants from '../constants'
import { AppVersion } from '../app-version'
import { HtmlContent } from '../html-content'
import { SocialMediaIcons } from '../social-media-icons'
import { TrafficJunky } from '../traffic-junky'
import { useGameInfoUrlQuery } from '../use-game-info-url-query'

const LinkWrapper = styled.div`
  ${css({
    p: 1,
  })}
  opacity: 0.5;
  transition: opacity 75ms ease-in;

  &:hover,
  &:active {
    opacity: 0.9;
  }
`

export function Footer() {
  const i18n = I18n.useI18n()
  const gameInfoQuery = useGameInfoUrlQuery()
  const location = ReactRouter.useLocation()
  const clientType = ReactRedux.useSelector(state =>
    Configuration.getClientType(state.configuration)
  )
  const isMobile = R.equals(Constants.ClientType.MOBILE_BROWSER, clientType)

  /**
   * Hides the footer:
   * - When playing any game on mobile
   * - You're on the Experts Area route
   */

  if (
    (isMobile && R.includes(gameInfoQuery.mode, ['classic', 'blitz'])) ||
    R.includes('experts-area', location.pathname)
  ) {
    return null
  }

  return (
    <Common.Box
      display="flex"
      backgroundColor="footer-background"
      padding={[1, 2]}
      flexDirection="column"
      alignItems="center"
      as="footer"
    >
      <Common.Box
        as="nav"
        color="g-text"
        display="flex"
        flexWrap="wrap"
        fontSize={1}
        justifyContent="center"
        pt={0}
      >
        <ReactRouter.Link
          to="/terms-and-conditions"
          data-testid="footer.link.terms"
        >
          <LinkWrapper>
            {i18n.translate('footer.terms-and-conditions')}
          </LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link to="/about" data-testid="footer.link.about">
          <LinkWrapper>{i18n.translate('footer.about-us')}</LinkWrapper>
        </ReactRouter.Link>
        <Common.Link
          to="https://casinoskyjp.zendesk.com/hc/ja"
          data-testid="footer.link.faq"
        >
          <LinkWrapper>{i18n.translate('footer.faq')}</LinkWrapper>
        </Common.Link>
        <ReactRouter.Link
          to="/privacy-policy"
          data-testid="footer.link.privacy-policy"
        >
          <LinkWrapper>{i18n.translate('footer.privacy-policy')}</LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/responsible-gaming"
          data-testid="footer.link.responsible-gaming"
        >
          <LinkWrapper>
            {i18n.translate('footer.responsible-gaming')}
          </LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/fairness-rng-testing-methods"
          data-testid="footer.link.fairness-rng-testing-methods"
        >
          <LinkWrapper>
            {i18n.translate('footer.fairness-rng-testing-methods')}
          </LinkWrapper>
        </ReactRouter.Link>
        <ReactRouter.Link
          to="/self-exclusion"
          data-testid="footer.link.self-exclusion"
        >
          <LinkWrapper>{i18n.translate('footer.self-exclusion')}</LinkWrapper>
        </ReactRouter.Link>
        <a
          href="https://heropartners.io/"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="footer.link.affiliate"
        >
          <LinkWrapper>{i18n.translate('footer.affiliate')}</LinkWrapper>
        </a>
      </Common.Box>
      <Common.Box
        fontSize={1}
        color="g-text"
        opacity="0.5"
        pt={2}
        textAlign="center"
        mx="auto"
        maxWidth="700px"
      >
        <HtmlContent
          as="small"
          html={{
            __html: i18n.translate('footer.legal-text.curacao'),
          }}
          data-testid="footer.legal-text"
        />
      </Common.Box>
      <Common.Box>
        <SocialMediaIcons />
      </Common.Box>
      <Common.Box
        pt={0}
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
      >
        <Common.Space p={0}>
          <a
            href="https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJblEzTUhsMk16VmhNQzlCWldwc1dVbDNNMEY1ZUhjOVBTSXNJblpoYkhWbElqb2lhbmh4T1VwamIzWllTbG93WldkeWJsRjVXbVJGWnowOUlpd2liV0ZqSWpvaU9HRmpabVJoT0dJMk1qTTFaRGRsTUdFNFpEZGxOVEV6T0RjNE5HVTVaamRqTWpGbFl6SXdNakV5TVRnNFpESXhOR00yWm1VMllURTBOV05rWWpsak5TSXNJblJoWnlJNklpSjk="
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/gcb.avif" alt="GCB license" height="42.5px" />
          </a>
        </Common.Space>
        <TrafficJunky />

        <Common.Space p={0}>
          <ReactRouter.Link
            to="/responsible-gaming"
            data-testid="footer.responsible-gaming.link"
          >
            <LazyLoad
              once
              offset={100}
              height="34px"
              placeholder={<Common.Text fontSize={1}>18+</Common.Text>}
            >
              <img
                src={i18n.translate('18-plus')}
                alt="18+"
                height="34"
                data-testid="footer.18-plus-logo"
              />
            </LazyLoad>
          </ReactRouter.Link>
        </Common.Space>
      </Common.Box>
      <AppVersion />
    </Common.Box>
  )
}

// for @loadable/components
export default Footer
